<template>
  <page-wrapper>
    <div slot="wrapper">
      <div class="help">
        <div class="help-item" v-for="(item, index) in qsp" :key="index">
          <div class="help-title">{{ item.title }}</div>
          <div class="help-p">{{ item.p }}</div>
        </div>
      </div>
    </div>
  </page-wrapper>
</template>
<script>
const qsp = [
  {
    title: "我明明抄袭的内容为何没测出",
    p:
      "测不出，原因很多，已经反复解释了，如果您抄教材，或尚未收录的最新论文、期刊等都是测不出的。哪怕你是下载了一篇网上的文章，已经收录了的，也会出现测不出的现象。因为数据库对重复率有一定的算法，并不是简单粗暴重复就给你算重复！我们只承诺：同篇文章，一字不动，格式也别变动，在我处检测的结果与官网的检测结果完全一致。建议：测出来的改动，测不出的且知道抄自哪里的也改动。现在测不出，不代表永远测不出，未来收录了那些内容，将来肯定就能测出来了",
  },

  {
    title: "我怎么知道我哪部分重复了？有修改意见嘛？",
    p:
      "检测报告压缩包里面有个全文比对报告，打开即可看到论文哪部分重复，对着红色重复部分修改即可！",
  },
  {
    title: "为何我已经修改了，还被查出来重复，句子都不太一样！",
    p:
      "维普数据库有严格的查重算法，如果你觉得只是简单增减几个字就能躲避维普查重，那你就小看维普查重的工程师了！",
  },
  {
    title: "有什么好的降重方法嘛？",
    p:
      "降重本质上是玩文字游戏，同一个意思换不同语句去表达。方法：自己可以网上多参考降重攻略哈",
  },
];
export default {
  data() {
    return {
      qsp,
    };
  },
};
</script>
<style lang="scss" scoped>
.help {
  padding: 26px 0 0 60px;
}
.help-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.help-item {
  margin-bottom: 20px;
}
.help-p {
  line-height: 22px;
}
</style>